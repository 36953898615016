import React from 'react';
import './EcosystemDiagram.css';


const EcosystemDiagram = () => {
  return (
    <div style={{ overflow: 'hidden', width: '100vw', height: '100vh', top:0, left:0, position: "absolute" , 'z-index':0, 'opacity':0.7,  'pointer-events': "none" }}>
      <iframe
        id="myIframe"
        src="https://mapofzones.com/home?columnKey=ibcVolume&amp;period=24h"
        style={{
          border: 'none',
          marginTop: '-120px',
          height: 'calc(100% + 150px)',
          width: 'calc(100% + 400px)',
        }}
      ></iframe>
    </div>
  );
};

export default EcosystemDiagram;
