import React, { useState, useEffect } from 'react';
import {
  ServicesContainer,
  ServicesCard,
  ServicesH1,
  ServicesH2,
  ServicesP,
  ServicesWrapper,
  ServicesIcon,
  CardFront,
  CardBack,
  FeaturedH1,
  FeaturedH2
} from './ServiceElements';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import ScrollingLogos from '../media/Media';

const Featured = () => {
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [isMobile, setIsMobile] = useState(false);

  const handleHover = (index) => {
    setHoveredIndex(index);
  };

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    checkIsMobile();
    window.addEventListener('resize', checkIsMobile);

    return () => {
      window.removeEventListener('resize', checkIsMobile);
    };
  }, []);


  const logos = [
    { src: 'images/logos/binance.png', alt: 'binance' },
    { src: 'images/logos/cmc.png', alt: 'coinmarketcap' },
    { src: 'images/logos/cfn.png', alt: 'cfn' },
    { src: 'images/logos/cg.png', alt: 'coingape' }
    // more logos
  ];
  const logos2 = [
    { src: 'images/logos/cosmos.png', alt: 'cosmos' },
    { src: 'images/logos/binance.png', alt: 'binance' },
    { src: 'images/logos/cmc.png', alt: 'coinmarketcap' },
    { src: 'images/logos/cfn.png', alt: 'cfn' },
    { src: 'images/logos/cg.png', alt: 'coingape' }
    // more logos
  ];
  const logos3 = [
    { src: 'images/logos/unisat.png', alt: 'unisat' },
    { src: 'images/logos/cmc.png', alt: 'coinmarketcap' },
    { src: 'images/logos/cfn.png', alt: 'cfn' },
    { src: 'images/logos/cg.png', alt: 'coingape' }
    // more logos
  ];

  // Configuration for the react-slick slider
  const sliderSettings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    swipeToSlide: true,
    adaptiveHeight: true
  };

  return (
    <ServicesContainer >
      <FeaturedH1>As Featured On</FeaturedH1>
      <ScrollingLogos logos={logos} speed={10} logoHeight="80px" />
      <FeaturedH2>Supported by</FeaturedH2>
      <ScrollingLogos logos={logos2} speed={10} logoHeight="50px" />
      <ScrollingLogos logos={logos3} speed={10} directions="right" logoHeight="50px" />
    </ServicesContainer>
  );
};

export default Featured;
