export const exampleTeamMembers = [
  {
    name: "Dexter",
    role: "CEO",
    description:
      "Dexter, our determined CEO, is driven by a relentless pursuit of success. With an unwavering spirit, he leads Bitmos with a passion for innovation.",
    image: "/images/team/dexter.png",
  },
  {
    name: "Monkey",
    role: "Lead Developer",
    description:
      "Monkey, our lead developer, brings over 10 years of blockchain industry experience. Specializing in JavaScript, Rust, Solidity, and CosmWasm, Monkey spearheads protocol development.",
    image: "/images/team/monkey.png",
  },
  {
    name: "Mandrake",
    role: "Business Lead",
    description:
      "Mandrake, our business development lead, brings extensive expertise in blockchain strategy. With a proven track record, Mandrake forges strategic partnerships and drives market success.",
    image: "/images/team/mandrake.png",
  },
  {
    name: "Krunk",
    role: "Product Lead",
    description:
      "Krunk, leads blockchain product development. Focused on user-friendly experiences, security, and scalability.",
    image: "/images/team/krunk.png",
  },
  {
    name: "Lee Lee",
    role: "Partnerships Director",
    description:
      "Lee Lee, leverages extensive experience in crypto venture capital and equity. She forms partnerships for integrating BRC20 tokens into the DeFi ecosystem.",
    image: "/images/team/leelee.png",
  },
  {
    name: "Dee Dee",
    role: "Finance Lead",
    description:
      "Dee Dee, has a solid background in financial regulation and compliance. With expertise in navigating regulatory frameworks, she ensures project stability and compliance.",
    image: "/images/team/deedee.png",
  },
];
