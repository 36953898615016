import React, { useState } from 'react';
import Navbar from '../Components/Navbar/Index';
import Sidebar from '../Components/SideBar/Index';
import ComingSoonNavbar from '../Components/ComingSoonNavbar/Index'   
import ComingSoonSidebar from '../Components/ComingSoonSideBar/Index'
import HowitWorks from '../Components/HowItWorks/Index';
import InfoSection from '../Components/Info/Index';
import { homeObj5, homeObj6, homeObj7 } from '../Components/Info/Data';
import Footer from '../Components/Footer/Index';
import ArcDiagram from '../Components/HowItWorks/ArcDiagram';
import IBCDiagram from '../Components/HowItWorks/IBCDiagram';
import Network from '../Components/network/Network';
import ArchitectureDiagram from '../Components/HowItWorks/ArchitectureDiagram';
import './HowItWorks.css'; // Import the CSS file from the same folder
const HowitWorksPage = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <ComingSoonSidebar isOpen={isOpen} toggle={toggle} />
      <ComingSoonNavbar toggle={toggle} />
      <HowitWorks />
      <InfoSection {...homeObj5} />
      <InfoSection {...homeObj6} />
      <InfoSection {...homeObj7} />
      <Footer />
    </>
  );
};

export default HowitWorksPage;
