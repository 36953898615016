import React from 'react'
import {
    ComingSoonSidebarContainer,
    ComingSoonIcon,
    ComingSoonCloseIcon,
    ComingSoonSidebarWrapper,
    ComingSoonSidebarMenu,
    ComingSoonSidebarLink,
    ComingSoonSidebarBtnWrap,
    ComingSoonSidebarRoute,

} from './ComingSoonSideBarElements'

const ComingSoonSidebar = ({ toggle, isOpen }) => {
    return (
        <>
            <ComingSoonSidebarContainer isOpen={isOpen} onClick={toggle}>
                <ComingSoonIcon onClick={toggle}>
                    <ComingSoonCloseIcon />
                </ComingSoonIcon>
                <ComingSoonSidebarWrapper>
                    <ComingSoonSidebarMenu>
                        <ComingSoonSidebarLink to="about" onClick={toggle}>
                            About
                        </ComingSoonSidebarLink>
                        <ComingSoonSidebarLink to="discover" onClick={toggle}>
                            Discover
                        </ComingSoonSidebarLink>
                        <ComingSoonSidebarLink to="services" onClick={toggle}>
                            Services
                        </ComingSoonSidebarLink>
                        <ComingSoonSidebarLink to="signup" onClick={toggle}>
                            Sign Up
                        </ComingSoonSidebarLink>
                    </ComingSoonSidebarMenu>
                    <ComingSoonSidebarBtnWrap>
                        <ComingSoonSidebarRoute to="/">Sign In</ComingSoonSidebarRoute>
                    </ComingSoonSidebarBtnWrap>
                </ComingSoonSidebarWrapper>
            </ComingSoonSidebarContainer>
        </>
    )
}

export default ComingSoonSidebar
