import React from 'react';
import './ScrollingLogos.css'; // Import the CSS

const ScrollingLogos = ({ logos, directions, logoHeight }) => {
  const doubledLogos = [...logos, ...logos]; // Duplicate the array for a seamless loop

  return (
    <div>
    
    (<div className={`scrolling-logos-container ${directions ? 'right' : 'left'}`}>
      {doubledLogos.map((logo, index) => (
        <img key={index} src={logo.src} alt={logo.alt} style={{ height: logoHeight }} />
      ))}
    </div>)
  
  </div>
  );
};

export default ScrollingLogos;
