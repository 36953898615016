import styled from 'styled-components'
import { MdArrowForward, MdKeyboardArrowRight } from 'react-icons/md'

export const HowItWorksContainer = styled.div`
background: #0c0c0c;
display: flex;
justify-content: center;
align-items: center;
padding: 0 30px;
height: 650px;
position: relative;
z-index: 1;

:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(0,0,0,0.2) 0%, rgba(0,0,0,0.6) 100%),
                linear-gradient(180deg, rgba(0,0,0,0.2) 0%, transparent 100%);
    z-index: 1
    
}
`

export const HowItWorksBg = styled.div`
position: absolute;
top: 0;
right: 0;
bottom: 0;
left: 0;
width: 100%;
height: 100%;
overflow: hidden;
`

export const HowItWorksVideoBg = styled.video`
width: 100%;
height: 100%;
-o-object-fit: cover;
object-fit: cover;
background: #232a34;

`

export const HowItWorksContent = styled.div`
    z-index: 3;
    max-width: 1200px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 8px 24px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
`;

export const LogoContainer = styled.div`
  width: 80%;
  display: flex;
  justify-content: center;
  align-items: center;
`;


export const LogoImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 100%;
  max-height: 100%;
`;


export const HowItWorksTitle = styled.h1`
color: #ffff;
font-size:48px;
text-align: center;

@media screen and (max-width: 768px) {
    font-size: 40px;
}

@media screen and (max-width: 480px) {
    font-size: 32px;
}
`

export const HowItWorksP = styled.p`
margin-top: 24px;
color: #fff;
fonst-size:24px;
text-align: center;
max-width: 600px;

@media screen and (max-width: 768px) {
    font-size: 24px;
}

@media screen and (max-width: 480px) {
    font-size: 18px;
}
`

export const HowItWorksBtnWrapper = styled.div`
margin-top: 32px;
display: flex;
flex-direction: column;
align-items: center;
`

export const HowItWorksArrowForward = styled(MdArrowForward)`
margin-left: 8px;
font-size: 20px;
`
export const HowItWorksArrowRight = styled(MdKeyboardArrowRight)`
margin-left: 8px;
font-size: 20px;
`