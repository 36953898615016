import React, { useState } from 'react'
import Navbar from '../Components/Navbar/Index'   
import Sidebar from '../Components/SideBar/Index'
import Hero from '../Components/Hero/Index'
import InfoSection from '../Components/Info/Index'
import Team from '../Components/Services/Index'
import Featured from '../Components/Featured/Index'
import { homeObj1, homeObj2, homeObj3, homeObj4 } from '../Components/Info/Data'
import Footer from '../Components/Footer/Index'

const Home = () => {

    const [isOpen, setIsOpen] = useState(false);

    const toggle = () => {
        setIsOpen(!isOpen);
    }

    return (
        <>
            <Sidebar isOpen={isOpen} toggle={toggle} />
           <Navbar toggle={toggle} />  
            <Hero id="home-section" />
            <InfoSection id="about-section" {...homeObj1} />
            <InfoSection  id="discover-section" {...homeObj2} />
            <InfoSection  id="how-section" {...homeObj3} />
            <Team id="team-section" /> {/* Add an "id" prop to the Team component */}
            <Featured id="team-section" /> {/* Add an "id" prop to the Team component */}
            <InfoSection  id="join-section" {...homeObj4} />
            <Footer />
        </>
    )
}

export default Home


