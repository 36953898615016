import React from 'react';
import Diagram from './Diagram';

const ArchitectureDiagram = () => {
  return (
    <div>
      <Diagram
        direction="vertical"
        steps={[
          { type: 'box', content: 'Smart Contracts and Applications', id: 'smartContracts' },
          { type: 'arrow', direction: 'down' },
          {
            type: 'box',
            id: 'bitmosNetwork',
            content: (
              <>
                Bitmos Network
                <Diagram
                  direction="vertical"
                  steps={[
                    { type: 'box', content: 'WrappedBTC', id: 'wrappedBTC' },
                    { type: 'box', content: 'WrappedBRC20', id: 'wrappedBRC20' },
                  ]}
                />
              </>
            ),
          },
          { type: 'arrow', direction: 'down' },
          { type: 'box', content: 'Bitcoin Blockchain', id: 'bitcoinBlockchain' },
        ]}
      />
    </div>
  );
};

export default ArchitectureDiagram;
