import React from 'react';
import ArcDiagram from '../HowItWorks/ArcDiagram';
import IBCDiagram from '../HowItWorks/IBCDiagram';
import ArchitectureDiagram from '../HowItWorks/ArchitectureDiagram';
import POSDiagram from '../HowItWorks/POSDiagram';
import EcosystemDiagram from '../Ecosystem/EcosystemDiagram';
import Code from '../HowItWorks/Code';
import {
  InfoContainer,
  InfoWrapper,
  InfoRow,
  BtnWrap,
  Column1,
  Column2,
  Heading,
  ImgWrap,
  Subtitle,
  TextWrapper,
  TopLine,
  Img
} from '../Info/InfoElements';

import { Button } from '../../Utilities/ButtonElements';

const InfoSection = ({
  lightBg,
  imgStart,
  topLine,
  lightText,
  darkText,
  headline,
  description,
  url,
  buttonLabel,
  alt,
  img,
  id,
  primary,
  dark,
  dark2
}) => {

  return (
    <>
      <InfoContainer lightBg={lightBg} id={id}>
        <InfoWrapper>
          <InfoRow imgStart={imgStart}>
         <Column1>
  <TextWrapper>
    <TopLine>{topLine.split('\n').map((line, index) => <React.Fragment key={index}>{line}<br /></React.Fragment>)}</TopLine>
    <Heading lightText={lightText}>{headline.split('\n').map((line, index) => <React.Fragment key={index}>{line}<br /></React.Fragment>)}</Heading>
    <Subtitle darkText={darkText} style={{ marginBottom: '1.5rem' }}>
      {description.split('\n').map((paragraph, index) => (
        <React.Fragment key={index}>
          {paragraph}
          <br />
        </React.Fragment>
      ))}
    </Subtitle>
    <BtnWrap>
      <Button
        to={url}
        smooth={true}
        duration={500}
        exact={true}
        primary={primary ? 1 : 0}
        dark={dark ? 1 : 0}
        dark2={dark2 ? 1 : 0}
      >
        {buttonLabel}
      </Button>
    </BtnWrap>
  </TextWrapper>
</Column1>


            <Column2>
              <ImgWrap>
                {id === 'arc' && <ArcDiagram />}
                {id === 'ibc' && <Code />}
                {id === 'architecture' && <ArchitectureDiagram />}
                {id === 'pos' && <POSDiagram />}
                {id === 'cosmos' && <EcosystemDiagram />}
                {id !== 'arc' && id !== 'ibc' && id !== 'architecture' && id !== 'pos' && id !== 'cosmos' && (
                  <Img src={img} alt={alt} /> // Use the default image if id does not match any specific diagram
                )}
              </ImgWrap>
            </Column2>
          </InfoRow>
        </InfoWrapper>
      </InfoContainer>
    </>
  );
};

export default InfoSection;
