import React from 'react';
import CodeBlock from '../codeblock/CodeBlock';

function Code() {
  const code = `
use std::vec::Vec;

pub struct BitMosMsg {
    pub version: u8,
    pub timestamp: u32,
    pub nonce: u32,
    pub emitter_chain_id: u16,
    pub emitter_address: [u8; 32],
    pub sequence: u64,
    pub consistency_level: u8,
    pub payload: Vec<u8>,

    pub guardian_set_index: u32,
    pub signatures: Vec<Signature>,

    pub hash: [u8; 32],
}

pub struct Signature {
    pub signer_index: u32,
    pub signature: [u8; 64],
}
  `;

  return <CodeBlock title="Wrapped BRC-20" code={code} />;
}

export default Code;
