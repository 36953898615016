import React from 'react';
import './CodeBlock.css'; // import the CSS from a separate file

const CodeBlock = ({ title, code }) => {
  const lines = code.split('\n');

  return (
    <div className="browser-window">
      <div className="header">
        <div className="buttons">
          <div className="close"></div>
          <div className="minimize"></div>
          <div className="maximize"></div>
        </div>
        <div className="address-bar">{title}</div>
      </div>
      <pre id="codeBlock">
        <code>
          {lines.map((line, index) => (
              <div key={index}>
              {line}
              {index === lines.length - 1 && <span className="caret"></span>}
            </div>
          ))}
        </code>
      </pre>
    </div>
  );
}

export default CodeBlock;
