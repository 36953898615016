import React from 'react';
import Diagram from './Diagram';

const POSDiagram = () => {
  return (
    <div>
      <Diagram
        direction="vertical"
        steps={[
          {
            type: 'box',
            content: (
              <>
                <div>Smart Contracts and Applications</div>
                <div style={{ marginTop: '10px', fontSize: '12px' }}>Fees</div>
              </>
            ),
            id: 'smartContracts',
          },
          {
            type: 'arrow',
            direction: 'down',
            content: (
              <>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: '10px', height: '2px', backgroundColor: 'black' }}></div>
                </div>
              </>
            ),
          },
          {
            type: 'box',
            content: (
              <>
                <div style={{ marginBottom: '10px', fontSize: '12px' }}>Bitmos Network</div>
                <div>Bitmos Network</div>
                <Diagram
                  direction="vertical"
                  steps={[
                    { type: 'box', content: 'Validators', id: 'validators' },
                  ]}
                />
              </>
            ),
            id: 'bitmosNetwork',
          },
          {
            type: 'arrow',
            direction: 'down',
            content: (
              <>
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ width: '10px', height: '2px', backgroundColor: 'black' }}></div>
                </div>
              </>
            ),
          },
          {
            type: 'box',
            content: (
              <>
                <div style={{ marginTop: '10px', fontSize: '12px' }}>Rewards</div>
                <div>bMOS Stakers</div>
              </>
            ),
            id: 'stakers',
          },
        ]}
      />
    </div>
  );
};

export default POSDiagram;
