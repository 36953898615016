import React, { useState } from 'react';
import {
    HeroContainer,
    HeroBg,
    VideoBg,
    ArrowForward,
    HeroContent,
    HeroP,
    HeroTitle,
    HeroBtnWrapper,
    ArrowRight,
    LogoContainer,
    LogoImage
} from '../Hero/HeroElements';
import { Button } from '../../Utilities/ButtonElements';
import Video from '../../Videos/wormhole_compressed.mp4';

const Hero = () => {
    const [hover, setHover] = useState(false);

    const onHover = () => {
        setHover(!hover);
    };

return (
  <>
    <HeroContainer>
      <HeroBg>
        <VideoBg autoPlay loop muted src={Video} type='video/mp4' />
      </HeroBg>
      <HeroContent>
        <LogoContainer>
          <LogoImage src="/images/logos/main_logo.png" alt="Bitmos logo" />
        </LogoContainer>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <HeroP>
            Unleash Bitcoin's power on Cosmos, where DeFi meets BTC
          </HeroP>
          <HeroBtnWrapper>
            <Button
              to="https://litepaper.bitmos.io"
              onMouseEnter={onHover}
              onMouseLeave={onHover}
              primary="true"
              dark="true"
            >
              Litepaper {hover ? <ArrowForward /> : <ArrowRight />}
            </Button>
          </HeroBtnWrapper>
        </div>
      </HeroContent>
    </HeroContainer>
  </>
);
};

export default Hero;
