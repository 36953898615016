import React from 'react';
import Diagram from './Diagram';

const ArcDiagram = () => {
  const arc = (
    <Diagram
      direction="vertical"
      steps={[
        { type: 'box', content: 'Bitcoin Blockchain', id: 'bitcoinBlockchain' },
        { type: 'arrow', direction: 'down' },
        { 
          type: 'box', 
          id: 'bitmosNetwork',
          content: (
            <>
              Bitmos Network
              <Diagram
                direction="vertical"
                steps={[
                  { type: 'box', content: 'WrappedBTC', id: 'wrappedBTC' },
                  { type: 'box', content: 'WrappedBRC20', id: 'wrappedBRC20' },
                ]}
              />
            </>
          ),
        },
        { type: 'arrow', direction: 'down' },
        { type: 'box', content: 'Smart Contracts and Applications', id: 'smartContracts' },
      ]}
    />
  );

  return <div>{arc}</div>;
};

export default ArcDiagram;
