export const homeObj1 = {
  id: 'about',
  lightBg: false,
  lightText: true,
  lightTextDesc: true,
  topLine: 'Bitmos Network',
  headline: 'Seamless Asset Transfer and Interoperability',
  description: 'Discover a world of limitless possibilities as Bitmos revolutionizes decentralized applications and financial services within the Cosmos ecosystem. \n\nExperience seamless asset transfer, cross-chain interoperability, and unlock new horizons in the world of blockchain innovation.',
  url: 'https://litepaper.bitmos.io',
  buttonLabel: 'Get Started',
  imgStart: false,
  img: require('../../Images/OT.svg').default,
  alt: 'about',
  dark: true,
  primary: true,
  darkText: false
};


export const homeObj2 = {
  id: 'problem',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "Bitcoin's Scalability Challenge:\nCongestion and Fees",
  headline: "BitMos:\nFast & Affordable Bitcoin Scaling",
  description: "Bitcoin's network growth has led to scalability challenges, causing congestion, soaring fees, and delays for low-fee transactions. With BitMos, experience a groundbreaking solution that addresses these pain points, enabling faster and more affordable Bitcoin transactions. \n\nSay goodbye to network congestion and high fees, and embrace seamless, efficient transactions on the BitMos network.",
  url: 'https://gateway.bitmos.io',
  buttonLabel: 'Enter Gateway',
  imgStart: true,
  img: require('../../Images/3.svg').default,
  alt: 'Piggybank',
  dark: false,
  primary: false,
  darkText: true
};



export const homeObj3 = {
  id: 'cosmos',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Expanding BRC-20 Horizons into the Cosmos',
  headline: 'Bitmos Unlocks the Potential of BRC20 Tokens in the Cosmos Ecosystem',
  url: 'litepaper.bitmos.io',
  description: 'Bitmos opens doors for BRC20 tokens to thrive alongside established players in the Cosmos Network, revolutionizing decentralized finance (DeFi) for all. \n\nExplore a world of opportunities as a BRC20 token holder, gaining access to a diverse range of DeFi applications, decentralized exchanges, and cross-chain liquidity pools within the expansive Cosmos ecosystem. Embrace the power of Bitmos and maximize the potential of your BRC20 tokens.',
  buttonLabel: 'Learn More',
  imgStart: false,
  img: require('../../Images/Account2.svg').default,
  alt: 'Paper',
  dark: false,
  primary: false,
  darkText: true
};


export const homeObj4 = {
  id: 'join',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Join our Community',
  headline: 'Stay Connected on Twitter',
  url: 'https://twitter.com/_bitmos_',
  description: 'Follow us on Twitter to stay updated with the latest news, announcements, and developments.',
  buttonLabel: 'Follow Us',
  imgStart: false,
  img: require('../../Images/Account2.svg').default,
  alt: 'Paper',
  dark: false,
  primary: false,
  darkText: true
};


export const homeObj5 = {
  id: 'arc',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'How does it work?',
  headline: 'Bitmos: Interoperability between BRC20 and Bitmos Blockchain',
  url: 'https://litepaper.bitmos.io',
  description: `The Bitmos protocol uses smart contracts and the Cosmos IBC (Inter-Blockchain Communication) protocol to enable interoperability between BRC20 tokens and the Bitmos Blockchain network. The process involves:\n\n- Transferring BRC20 tokens to the Bitmos Blockchain\n- Minting wrapped versions of the tokens (CW-20 or CW-721) on the Bitmos chain\n- Facilitating cross-chain transfers via IBC relayers\n- Tokens can be traded on major Dexes across the Cosmos ecosystem.`,
  buttonLabel: 'Learn More',
  imgStart: false,
  img: require('../../Images/Account2.svg').default,
  alt: 'Paper',
  dark: false,
  primary: false,
  darkText: true
};



export const homeObj6 = {
  id: 'ibc',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: 'Bitmos: A Two-layered Architecture for Enhanced Scalability',
  headline: 'Process of BRC20 Token Creation and Transfer',
  url: 'litepaper.bitmos.io',
  description: `Token Movement within Bitmos:\n1. Create a BRC20 token on the Bitcoin network using the Ordinals protocol.\n2. Transfer the BRC20 token to a designated Bitcoin address acting as a bridge to Bitmos.\n3. The Bitmos bridge contract monitors token transfers, triggering the minting of wrapped tokens on Bitmos.\n4. Original BRC20 tokens are locked, and wrapped BRC20 tokens are minted on Bitmos.\n5. Confirmed on the Bitmos blockchain, wrapped BRC20 tokens become usable on the Cosmos network and are sent to Bitmos addresses.`,
  buttonLabel: 'Learn More',
  imgStart: false,
  img: require('../../Images/Account2.svg').default,
  alt: 'Paper',
  dark: false,
  primary: false,
  darkText: true
};



export const homeObj7 = {
    id: 'pos',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    topLine: 'Transaction Fees and Network Security on Bitmo',
    headline: 'bMOS Tokens: Transaction Fees and Network Validation',
    url:'litepaper.bitmos.io',
   description: `Transactions on Bitmos use bMOS tokens as the native currency.\n\n- Users pay transaction fees in bMOS tokens.\n- Validators stake bMOS tokens and validate transactions for network security.\n- Validators receive transaction fees in bMOS tokens as rewards.\n- Block creation and confirmation maintain the integrity of the Bitmos network.`,
   buttonLabel: 'Learn More',
    imgStart: false,
    img: require('../../Images/Account2.svg').default,
    alt: 'Paper',
    dark: false,
    primary: false,
    darkText: true
}
